import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { getGA } from "./urlConstants.js";

ReactGA.initialize(getGA());
const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});

//For GA
export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const ProductData = (id, model, available, storeId) => {
  ReactGA.plugin.require("ecommerce");
  ReactGA.plugin.execute("ecommerce", "addTransaction", {
    id: id,
    name: model,
    quantity: parseInt(available),
    variant: storeId,
  });
  ReactGA.plugin.execute("ecommerce", "send", null);
  ReactGA.plugin.execute("ecommerce", "clear", null);
};
export default history;
