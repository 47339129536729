import config from "./config";

const getEnv = () => {
  const environments = {
    development: "Prod",
    QA: "qa",
    production: "prod",
    uat: "uat",
  };
  return environments[config.env];
};

export const getEnv1 = () => {
  const environments = {
    development: "dev",
    QA: "qa",
    production: "prod",
    uat: "uat",
  };
  return environments[config.env];
};

export const getDomain = () => {
  const domainUrls = {
    uat: "uat.membersonline.com/mol2/website/identity/connect/",
    QA: "uat.membersonline.com/mol2/website/identity/connect/",
    development: "uat.membersonline.com/mol2/website/identity/connect/",
    production: "www.membersonline.com/mol2/website/identity/connect/",
  };
  return domainUrls[config.env];
};

export const getDealNavigator = () => {
  const dealNavigatorLinks = {
    uat:
      "https://uat-aem.membersonline.com/content/MOL/appredirect.html?appGuid=40F42F3C-2845-4CDB-92A6-8FEACC8DB335",
    QA:
      "https://uat-aem.membersonline.com/content/MOL/appredirect.html?appGuid=40F42F3C-2845-4CDB-92A6-8FEACC8DB335",
    development:
      "https://uat-aem.membersonline.com/content/MOL/appredirect.html?appGuid=40F42F3C-2845-4CDB-92A6-8FEACC8DB335",
    production:
      "https://aem.membersonline.com/content/MOL/appredirect.html?appGuid=40F42F3C-2845-4CDB-92A6-8FEACC8DB335",
  };
  return dealNavigatorLinks[config.env];
};

export const mriReceiptURL =
  "http://10.113.112.3/api/mribaseapis/MRIMembers/Member/";

export const getPalletImage = () => {
  const palletImageUrls = {
    uat: "https://dam-static-uat.truevalue.com/dsimages/",
    QA: "https://dam-static-dev.truevalue.com/dsimages/",
    development: "https://dam-static-dev.truevalue.com/dsimages/",
    production: "https://dam-static.truevalue.com/dsimages/",
  };
  return palletImageUrls[config.env];
};
export const getImageURl = () => {
  const palletImageUrls = {
    uat: "https://dam-static-uat.truevalue.com",
    QA: "https://dam-static-dev.truevalue.com",
    development: "https://dam-static-dev.truevalue.com",
    production: "https://dam-static.truevalue.com",
  };
  return palletImageUrls[config.env];
};

export const GET_PARTICIPATION_DISTRIBUTION = () => {
  const url = {
    uat: "https://uat-aem.membersonline.com/content/MOL.html",
    QA: "https://qa-aem.membersonline.com/content/MOL.html",
    development: "https://uat-aem.membersonline.com/content/MOL.html",
    production: "https://aem.membersonline.com/content/MOL.html",
  };
  return url[config.env];
};

export const getGA = () => {
  const gaTrackingId = {
    development: "UA-152521330-1",
    QA: "UA-152521330-1",
    uat: "UA-152521330-1",
    production: "UA-42728270-3",
  };
  return gaTrackingId[config.env];
};

export const getRedirectUri = () => {
  const environments = {
    development: "https://mri-dev.truevalue.com",
    QA: "https://mri-uat.truevalue.com",
    uat: "https://mri-uat.truevalue.com",
    production: "https://mri.truevalue.com",
  };
  return environments[config.env];
};

export const deals = "Hot Deals";

export const getRedirectUriNNWH = () => {
  const environments = {
    development: "https://nwa-dev.truevalue.com",
    QA: "https://nwa-qa.truevalue.com",
    uat: "https://nwa-uat.truevalue.com",
    production: "https://nwa.truevalue.com",
  };
  return environments[config.env];
};
export const getRedirectUriMOL = () => {
  const environments = {
    development: "https://uat-aem.membersonline.com/content/MOL.html",
    QA: "https://uat-aem.membersonline.com/content/MOL.html",
    uat: "https://uat-aem.membersonline.com/content/MOL.html",
    production: "https://aem.membersonline.com/content/MOL.html",
  };
  return environments[config.env];
};
export const getRedirectReunionApp = () => {
  const environments = {
    development:
      "https://fivectvmobiledev.azurewebsites.net/SSO/Authenticate.aspx?",
    uat: "https://fivectvmobiledev.azurewebsites.net/SSO/Authenticate.aspx?",
    QA: "https://fivectvmobiledev.azurewebsites.net/SSO/Authenticate.aspx?",
    production: "https://tvmobile.me/SSO/Authenticate.aspx?",
  };
  return environments[config.env];
};

export const JWKS_URI = () => {
  const environments = {
    development:
      "https://uat.membersonline.com/mol2/website/identity/.well-known/jwks",
    uat: "https://uat.membersonline.com/mol2/website/identity/.well-known/jwks",
    QA: "https://uat.membersonline.com/mol2/website/identity/.well-known/jwks",
    production:
      "https://www.membersonline.com/mol2/website/identity/.well-known/jwks",
  };
  return environments[config.env];
};

export const FOOTER_TV_LINK = () => {
  const environments = {
    development:
      "https://uat-aem.membersonline.com/content/MOL/true-value-company.html",
    uat:
      "https://uat-aem.membersonline.com/content/MOL/true-value-company.html",
    QA: "https://uat-aem.membersonline.com/content/MOL/true-value-company.html",
    production:
      "https://aem.membersonline.com/content/MOL/true-value-company.html",
  };
  return environments[config.env];
};

export const DEALER_PROGRAMS = () => {
  const environments = {
    development:
      "https://uat-aem.membersonline.com/content/MOL/product/vendor-policies/dealer-programs.html",
    uat:
      "https://uat-aem.membersonline.com/content/MOL/product/vendor-policies/dealer-programs.html",
    QA:
      "https://uat-aem.membersonline.com/content/MOL/product/vendor-policies/dealer-programs.html",
    production:
      "https://aem.membersonline.com/content/MOL/product/vendor-policies/dealer-programs.html",
  };
  return environments[config.env];
};

export const UPS_URL =
  "http://wwwapps.ups.com/WebTracking/processInputRequest?HTMLVersion=5.0&sort_by" +
  "=status&error_carried=true&tracknums_displayed=5&TypeOfInquiryNumber=T&loc=en_US" +
  "&AgreeToTermsAndConditions=yes&track.x=22&track.y=10";

export const FEDEX_URL =
  "http://www.fedex.com/Tracking?language=english&cntry_code=us&tracknumbers=trackingNumber";

export const NETWAREHOUSE_NAV =
  "https://aem.membersonline.com/content/MOL/true-value-company/story/netwarehouse-2020.html";

export const SPECIAL_EVENTS =
  "https://aem.membersonline.com/content/MOL/product/story/sr-20-ctb-last-chance.html";

export const VENDOR_URL =
  "https://e00vvbpm8c.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetVendorInfoProdCount?vName=";

export const VENDOR_DETAILS_URL =
  "https://zopr484vze.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const RETAILASSORTMENT =
  "https://n099kbxi09.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const PLANOGRAM_LISTING =
  "https://5lmo06dq1l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetItemsByPlanogramNumber?";

export const PLANOGRAM_FILTER =
  "https://5lmo06dq1l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostFilterPlanogramItems";

export const ZONE_INFO =
  "https://2229h0snl5.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/?storeId=";

export const ORDERPAD =
  "https://k50tuskmge.execute-api.us-east-2.amazonaws.com/" + getEnv1() + "?";

export const ORDER_UPLOAD_URL =
  "https://rx20rdbrqh.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const IMAGE_UPLOAD_URL =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/UploadHomeTileImage";

export const IMAGE_UPDATE_URL =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/UpdateHomeTileImage";

export const HOME_IMAGE_URL =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/GetHomeTileImage";

export const DELETE_HOME_TILE =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/DeleteHomeTileImage";

export const REUNION_OFFERS =
  "https://pjz84o2lxf.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const REUNION_PRODUCT_LIST =
  "https://pjz84o2lxf.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/listeventitems";

export const REUNION_EVENT_LISTING =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/eventslist";

export const REUNION_OFFERING_VENDORS =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/vendorlist";

export const PREVIEW_CIRCULAR =
  "https://nw.truevalue.com/ImageUpload/promotions/";

export const ORDER_STATUS =
  "https://2c508aikde.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetAllOrdersByStoreId";

export const ORDER_HISTORY_PDP =
  "https://dmndm2rz0f.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostInvoicedOrdersForItem";

export const SHIP_LATER_API =
  "https://bj3cwtyy5h.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const FILL_RATES_API =
  "https://2s3kd5evqe.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const ADDRESS_BOOK_API =
  "https://u965hxd3h8.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const ADDRESS_BOOK_UPLOAD_API =
  "https://63ebqjr634.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const SCANNINGIMAGEAPI =
  "https://e54atpw430.execute-api.us-east-2.amazonaws.com/" + getEnv();

export const getpreference =
  "https://08y89idyli.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/getPreferenceDetails";

export const updatepreference =
  "https://08y89idyli.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/updatePreferenceDetails";

export const GET_ASK_TRUEVAL =
  "https://m2f251y45b.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Contact";

export const ASK_TV_EMAIL =
  "https://m2f251y45b.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Email";

export const ORDER_ITEM_DETAILS =
  "https://2c508aikde.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostDisplayItemsByOrderNbrInvoiceNbr";

export const EDIT_ORDER_ITEM_DETAILS =
  "https://d1bk3a7bbf.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const PROMO_ITEM_DETAILS =
  "https://0qx05fg0pb.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostDisplayPromoShipOrderItems";

export const CHECKOUT_VALIDATION =
  "https://z2ycbc66bd.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/ValidateOrderItems";

export const CANCELED_ITEMS =
  "https://m6uugv4u4j.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/cancelledEventItemList";

export const MULTIPLE_ITEMS_DETAILS =
  "https://3ai3h4if2l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/?itemId=";

export const MULTIPLE_ORDER_PAD_ITEMS =
  "https://3ai3h4if2l.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const PRODUCT_DETAILS =
  "https://k50tuskmge.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "?itemId=";

export const MONTHLY_SPECIALS_PDP =
  "https://3pg2lom685.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetMSItemDetails";

export const ALL_WAREHOUSE_DETAILS =
  "https://zzkjqt7g7c.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetAll/?itemNumber=";

export const IMPULSE_MERCHANDISING_ITEMS =
  "https://p4pfomi3n0.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/listmerchitems";

export const departmentUrl =
  "https://sfvvxhnc2i.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const planogramsUrl =
  "https://5lmo06dq1l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetPlanogramsFlyout";

export const newItemsUrl =
  "https://vmia2n2e51.execute-api.us-east-2.amazonaws.com/" +
  getEnv() +
  "/?date=2017-04-07%2000:00:00"; // not found in excel sheet

export const closeOutItemsUrl =
  "https://lvgedf8rbg.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const productDetailsUrl =
  "https://tehaz7vni9.execute-api.us-east-2.amazonaws.com/" +
  getEnv() +
  "?itemId="; // did not found in excel

export const addProductUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Update";

export const addManyProductsUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Update";

export const createNAddManyUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Create";

export const createNAddCartUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Create";

export const addProductOrderPadUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Update";

export const addItemstoCartPlanogramUrl =
  "https://5lmo06dq1l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostPreparePlanogramOrder";

export const createNAddCartOrderPadUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Create";

export const addReunionCartProductUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Update";

export const addRACartProductUrl =
  "https://n099kbxi09.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/AddToCart";

export const deleteCartUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/DeleteCart";

export const removeProductUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/DeleteItem";

export const updateQuantityUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Update";

export const updateOrUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Update";

export const viewAllCartsUrl =
  "https://nlyleb1wr2.execute-api.us-east-2.amazonaws.com/" + getEnv1() + "/";

export const createCartUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Create";

export const getCartUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetShoppingCartId";

export const editCartNameUrl =
  "https://nlyleb1wr2.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/updateShoppingCartName";

export const notifyUrl =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Notify";

export const placeOrderUrl =
  "https://6oaqiv5qy5.execute-api.us-east-2.amazonaws.com/" +
  getEnv() +
  "/?storeId=";

export const placeOrderMainrameUrl = `https://zvu7rgepx4.execute-api.us-east-2.amazonaws.com/${getEnv1()}`; // not listed in excel sheet

export const orderConfirmationUrl =
  "https://3ai3h4if2l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/?itemId=";

export const productListingUrl =
  "https://i3erm0k28l.execute-api.us-east-2.amazonaws.com/" + getEnv1() + "/";

export const imProductListingUrl =
  "https://p4pfomi3n0.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "?pog=";

export const domListingUrl =
  "https://v2cvfkc0md.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/DOMViewItems";

export const competitorsDataUrl = `https://ytefshb84h.execute-api.us-east-2.amazonaws.com/${
  getEnv1() === "prod" ? `${getEnv1()}/?item_upc=` : "dev?item_upc="
}`;

export const dealsOfTheMonthUrl =
  "https://nv38zu7ro4.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/?memNbr=";

export const addToFavoritesUrl =
  "https://wi960gxo33.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/Insert";

export const removeFromFavoritesUrl =
  "https://wi960gxo33.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/DeleteItem";

export const getFavoritesUrl =
  "https://wi960gxo33.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetFavoriteList";

export const getFavoritesItems =
  "https://wi960gxo33.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetFavoriteItems";

export const addFavoriteVendors =
  "https://5p4gaatm6f.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/AddFavoriteVendors";

export const getFavoriteVendors =
  "https://5p4gaatm6f.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetFavoriteVendors";

export const deleteFavoriteVendors =
  "https://5p4gaatm6f.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/DeleteFavoriteVendors";

export const getVendorIdfromName =
  "https://9qfg454gva.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "?vName=";

export const newItemsCarouselUrl =
  "https://65lj58ktki.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetAll";

export const imCategoriesUrl =
  "https://cp5znhuij0.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const pogListUrl =
  "https://bh97d1ty9i.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "?category=";

export const loginUrl =
  "https://oevskyryaj.execute-api.us-east-2.amazonaws.com/" + getEnv();

export const planogramsListByDeptUrl =
  "https://5lmo06dq1l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetPlanogramsByDepartment?storeId=";

export const PLANOGRAM_LIST_FILTER =
  "https://5lmo06dq1l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostFilterPlanograms";

export const account = "https://aem.membersonline.com/content/MOL/spa.html";

export const store = "https://aem.membersonline.com/content/MOL/spa.html";

export const EDIT_ORDERS =
  "https://ti31r2h83m.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const userObjectUrl =
  "https://glz9ml2jek.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostRetrieveNWUser";

export const RESTOCKINGURL =
  "https://0x56f6mkvd.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/RestockingTime";

export const RESTOCKINGORDER =
  "https://0x56f6mkvd.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/RestockingOrder";

export const VARIABLEPRICING =
  "https://zl972dm5li.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const INVOICE_ORDER_HISTORY =
  "https://dmndm2rz0f.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostInvoicedOrdersForItem";

export const MAP_REDIRECTION_URL =
  "https://aem.membersonline.com/content/MOL/product/vendor-policies/mapp.html";

export const VENDOR_POLICY_MAP_REDIRECTION_URL =
  "https://aem.membersonline.com/content/MOL/product/vendor-policies.html";

export const RA_PLACE_ORDER_URL =
  "https://i2rp8224ya.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PlaceAssortmentOrder";

export const RE_PLACE_ORDER_URL =
  "https://6pmq51ay3h.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/RetailEventsSubmit";
export const HOT_DEALS_URL =
  "https://5kjj20vqyj.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetHotDeals";

export const PLANOGRAM_PLACE_ORDER =
  "https://78t66ocflb.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/submitPlanogram";

export const M4S_MONTHLY_PLANNER =
  "https://nw.truevalue.com/nw/M4S/M4S_MonthlyPlanner.pdf";

export const IMPULSE_MERCHANDISING_GUIDE =
  "https://aem.membersonline.com/content/dam/docs/product/2020Spring-Impulse-Merchandising-Guide.pdf";

export const MONTHLY_SPECIALS =
  "https://3pg2lom685.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const MONTHLYSPECIAL_ADDTOCART =
  "https://3pg2lom685.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/AddToCart";

export const FLASH_SALES_URL =
  "https://1wo5gj0qg2.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/getFSIndicator";

export const FLASH_SALES_DATA =
  "https://krxqx36o02.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const MONTHLY_SPECIALS_PLACE_ORDERS =
  "https://qxrcdpuirg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/MonthlySpecialSubmit";
export const finelineProductListingUrl =
  "https://8gfcoz2wwj.execute-api.us-east-2.amazonaws.com/" + getEnv1();

export const finelineSaveCatalogUrl = `https://ym3svviqwj.execute-api.us-east-2.amazonaws.com/${getEnv1()}/AddCatalog`;
export const finelineGetCatalogsUrl = `https://ym3svviqwj.execute-api.us-east-2.amazonaws.com/${getEnv1()}/ListCatalog`;
export const finelineDeleteCatalogUrl = `https://ym3svviqwj.execute-api.us-east-2.amazonaws.com/${getEnv1()}/DelCatalog`;
export const finelinePDFUrl =
  "https://test-pdf-tv.s3.us-east-2.amazonaws.com/Patio+Ordering+Book+FR19_FINAL.pdf";
// "http://test-pdf-tv.s3.us-east-2.amazonaws.com/chunckpdf77421e49-c01d-4d2e-b5a0-629081f9f34a.pdf";

export const EVENT_ENABLE =
  "https://n099kbxi09.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/isMarketSet";

export const updatePromodetails =
  "https://0qx05fg0pb.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/UpdatePromoShipOrderItems";

export const acceptDeclineurl =
  "https://0qx05fg0pb.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/AcceptOrDeclinePromoShipOrderItems";

export const PROMOSHIP_FETCHORDERS =
  "https://0qx05fg0pb.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostDisplayPromoShipOrders";

export const SHOPPING_AREA_LIST =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/shoppingarealist";

export const ASSORTMENT_LISTS =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/AssortmentLists";

export const ASSORTMENT_ITEMS =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/AssortmentItems";

export const REUNION_ITEM_LISTING =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/reunionitems ";

export const PALLET_LISTING =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/pallets";

export const PALLET_ITEM_LISTING =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/palletitems";

export const MARKET_ADD_TO_CART =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/marketAddToCart";
export const SHOPPING_METADATA_LIST =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/getShoppingMetadata";

export const GET_HOME_TILE_IMAGELIST =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/GetHomeTileImageList";

export const SHOPPING_ADMIN_LIST =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/getShoppingArea";

export const UPDATE_SHOPPING_AREA =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/updateShopping";

export const GET_CONFIG =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/getConfig";

export const UPDATE_CONFIG =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/updateConfig";

export const UNMAPPED_PROMOS =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/getNewNNWPromos";

export const UPDATE_UNMAPPED_PROMOS =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/updatePromoShopping";

export const GET_ITEM_RESTRICTION =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/getItemRestriction";

export const UPDATE_ITEM_RESTRICTION =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/updateItemRestriction";

export const GET_ASSORTMENT_RESTRICTION =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/getAssortmentRestriction";

export const UPDATE_ASSORTMENT_RESTRICTION =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/assortmentRestriction";

export const GET_DROPSHIP_REPORT =
  "https://lgha1gyf45.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/admin/dropshipReport";

export const msWarehouseCart =
  "https://cwpno7337d.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/MultiStoreAddToCart ";

export const msAssortment =
  "https://3pg2lom685.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/WholeSalesAddToCart ";

export const msMonthlyspecials =
  "https://3pg2lom685.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/WholeSalesAddToCart ";

export const msPlanogram =
  "https://5lmo06dq1l.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostPreparePlanogramOrder";

export const msMonthlyspecialsReunion =
  "https://xe3bj56ddg.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/multiStoreMarketAddToCart";

export const VENDOR_DROPSHIP_VENDOR_LIST =
  "https://4vqoq7tvze.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/dropshipvendorlist";

export const VENDOR_DROPSHIP_ITEM_LIST =
  "https://4vqoq7tvze.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/dropshipitemlist";

export const VENDOR_LIST_EBOOKS =
  "https://n6alkakvsc.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/VendorList";

export const raAssortmentMs =
  "https://n099kbxi09.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/PostAssortmentMultiStoreAddToCart";

export const DROPSHIP_PALLETLISTING =
  "https://4vqoq7tvze.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/dropshippalletlist";

export const DROPSHIP_PALLET_ITEMLISTING =
  "https://4vqoq7tvze.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/dropshippalletitemlist";

export const DROPSHIP_SUBMIT_EMAIL =
  "https://4vqoq7tvze.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/dropshipsubmitemail";

export const DROPSHIP_VENDOR_LISTING =
  "https://4vqoq7tvze.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/dropshippalletvendorlist";
export const assortmentImpulepdf =
  "https://apps.membersonline.com/VisualMerchandising/catalog/VisMerch/";
export const assortmentPlanopdf =
  "https://apps.membersonline.com/planogram/catalog/";
export const GET_CART_INFO =
  "https://nlyleb1wr2.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetCartsList/?userID=";
export const scanningLog =
  "https://dvgtumuc33h6maa3qwcxr2fjie0zazir.lambda-url.us-east-2.on.aws/";
export const TRANSPLACE_QUICK_QUOTES =
  "https://cm8zn49kl0.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/GetFreightRate";

export const CTBASSORTMENT =
  "https://n099kbxi09.execute-api.us-east-2.amazonaws.com/" +
  getEnv1() +
  "/flyout?type=CTB";

export const getRestrictedItemUri = () => {
  const environments = {
    development:
      "https://dev-aem.membersonline.com/content/MOL/appredirect.html?appGuid=0840EFE0-A2DA-4BC3-9F3A-EC061A4C5CDF",
    QA:
      "https://qa-aem.membersonline.com/content/MOL/appredirect.html?appGuid=0840EFE0-A2DA-4BC3-9F3A-EC061A4C5CDF",
    uat:
      "https://uat-aem.membersonline.com/content/MOL/appredirect.html?appGuid=0840EFE0-A2DA-4BC3-9F3A-EC061A4C5CDF",
    production:
      "https://aem.membersonline.com/content/MOL/appredirect.html?appGuid=0840EFE0-A2DA-4BC3-9F3A-EC061A4C5CDF",
  };
  return environments[config.env];
};

export const getMRIDocumentsUri = () => {
  const environments = {
    development:
      "https://mri-api-dev.truevalue.com/api/mribaseapis/MRIMembers/Member",
    QA: "https://mri-api-dev.truevalue.com/api/mribaseapis/MRIMembers/Member",
    uat: "https://mri-api-uat.truevalue.com/api/mribaseapis/MRIMembers/Member",
    production:
      "https://mri-api.truevalue.com/api/mribaseapis/MRIMembers/Member",
  };
  return environments[config.env];
};
export const downloadMRIDocumentUri = () => {
  const environments = {
    development:
      "https://mri-api-dev.truevalue.com/api/mribaseapis/MRIObjects/index",
    QA: "https://mri-api-dev.truevalue.com/api/mribaseapis/MRIObjects/index",
    uat: "https://mri-api-uat.truevalue.com/api/mribaseapis/MRIObjects/index",
    production:
      "https://mri-api.truevalue.com/api/mribaseapis/MRIObjects/index",
  };
  return environments[config.env];
};
