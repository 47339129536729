export const filters = {
  AsstmentLevel: {
    displayName: "Assortment Level",
    requestString: "AssortmentLevel",
  },
  coreItems: { displayName: "Core Items", requestString: "CoreItems" },
  DisconItems: {
    displayName: "Discontinued Items",
    requestString: "Discontinued",
  },
  DisItems: { displayName: "To be Discontinued", requestString: "TobeDiscon" },
  Greener: { displayName: "Greener Options", requestString: "GreenerOptions" },
  MadeInUSA: { displayName: "Made In USA", requestString: "MadeInUSA" },
  NewItems: { displayName: "New", requestString: "NewItems" },
  NonDis: { displayName: "Non Discontinued Items", requestString: "NonDiscon" },
  Class: { displayName: "Fine Line Class", requestString: "FineLineClass" },
  SubClass: {
    displayName: "Fine Line Sub Class",
    requestString: "FineLineSubClass",
  },
  "Vendor Name": { displayName: "Vendor Name", requestString: "Vendor" },
  DemandClass: { displayName: "Demand Class", requestString: "Demand" },
  Department: {
    displayName: "Fine Line Department",
    requestString: "FineLineDepartment",
  },
};

export const domFiltersSet = [
  {
    name: "Visibility Level",
    valueList: ["SH", "LL", "MC", "OF", "NS"],
  },
];

export const MY_WAREHOUSE = "my-warehouse";
export const ALL_WAREHOUSE = "all-warehouse";

export const FLASH_SALES = "FLASH SALE";

export const RETAIL_ASSORTMENT = "/retailAssortments/:deptName/";
export const CTB_ASSORTMENT_DEPT = "/ctbAssortments/:deptName/";
export const REUNION_ASSORTMENT = "/reunionAssortments/:deptName/";
export const CTB_ASSORTMENT = "/ctbAssortments";

export const footerContent =
  "This password protected site is for True Value Company Members only";
export const footerYearContent = `@${new Date().getFullYear()} True Value Company`;

export const REUNION_APP = "Reunion App";
export const REUNION_ORDERING = "Reunion Ordering";
export const ADMIN = "Admin";
export const CIPHER = "Order Upload (Excel / Cipher)";

export const PAGE_LIMIT = 4000;

export const nonStockMessage =
  "Item is not stocked in your RDC. Please review other RDCs to place an order.";

export const memberViewConstants = {
  MEMBER_COST: "Member Cost",
  SUGG_RETAIL: "Sugg. Retail",
  ORIG_COST: "Original Cost",
  CURR_COST: "Current Cost",
  SAVING: "Savings",
  CREDIT_AMOUNT: "Credit Amount",
  REGULAR_COST: "Regular Cost",
  ORDER_PAD_COST: "Cost",
  MARKDOWN_CREDIT_AMOUNT: "Markdown Credit Amount",
  RESET_CREDIT_AMOUNT: "Reset Credit Amount",
  MEMBER_MISSING_COST: "Member Missing Cost",
  TOTAL_MEMBER_COST: "Total Member Cost",
  COST_MISSING_ITEMS: "Cost For Missing Items",
  COST_ALL_ITEMS: "Cost For All Items",
  MARKDOWN: "Markdown",
  FINAL_COST: "Final Cost After Markdown",
  COST: "Cost",
  EXTENDED_COST: "Ext. Cost",
  TOTAL_COST: "TOTAL COST",
  TOTAL: "Total",
  TOTAL_EXTENDED_COST: "TOTAL EXTENDED COST",
  ORDER_TOTAL: "Order Total",
  SUB_TOTAL: "Sub Total",
  CREDIT_VALUE: "Credit Value",
  EXTENDED_COST_ORDER_PAD: "Extended Cost",
};
export const customerViewConstants = {
  MEMBER_COST: "MC",
  SUGG_RETAIL: "SR",
  ORIG_COST: "OC",
  CURR_COST: "CC",
  SAVING: "SV",
  CREDIT_AMOUNT: "CR",
  ORDER_PAD_COST: "CS",
  REGULAR_COST: "RC",
  MARKDOWN_CREDIT_AMOUNT: "MCR",
  RESET_CREDIT_AMOUNT: "RCR",
  MEMBER_MISSING_COST: "MMR",
  TOTAL_MEMBER_COST: "TC",
  COST_MISSING_ITEMS: "CIT",
  COST_ALL_ITEMS: "CIA",
  MARKDOWN: "MA",
  COST: "MC",
  FINAL_COST: "CFM",
  EXTENDED_COST: "EC",
  TOTAL_COST: "TC",
  TOTAL: "TC",
  TOTAL_EXTENDED_COST: "TC",
  ORDER_TOTAL: "OT",
  SUB_TOTAL: "ST",
  CREDIT_VALUE: "CV",
  EXTENDED_COST_ORDER_PAD: "EC",
};
export const whsStockCode = {
  STATUS_DISCONTINUED: "DIS",
  STATUS_MARKED_OUT: "M/O",
  STATUS_NEW_ITEM: "N/I",
  STATUS_OUT_OF_SEASON: "O/S",
  STATUS_TEMP_OUT: "T/O",
  STATUS_OUT_FOR_SEASON: "OFS",
  STATUS_EXTENDED_OUT_OF_STOCK: "EXS",
  STATUS_EMERGENCY_OUT_OF_STOCK: "EMR",
  STATUS_END_OF_LIFE: "EOL",
  STATUS_PROMO_IN_OUT: "PIO",
};
export const keyMap = {
  SCROLL_TO_TOP: "ctrl+home",
};

export const REUNIONORDERING = "/reunionOrdering/";
export const EVENTS = "/events/";
export const EVENT_NAME = "/eventName/";
export const TRUE = "true";
export const ASSORTMENTS = "/assortments/";

export const ASSORTMENT_UNAVAILABLE_TEXT =
  "No Reunion Assortments to display! ";
export const NOT_ELIGIBLE_TEXT =
  "Not Eligible for Credit, Previously Purchased ";
export const DETAIL_TEXT_ASSORTMENT =
  "*The credit and/or dating below will only be applied to the first assortment ordered per Retail Assortment Category. Credits will appear as a credit on a future statement.";
export const RESTRICTED_COUNTRY =
  "* The following item(s) are restricted in your county and/or state: ";
export const ASSORTMENT_ADJUSTMENT =
  "(Your assortment cost will adjust accordingly since you will not be receiving these item(s).) ";
export const ASSORTMENT_RESTRICTED = "This assortment is restricted. ";
export const ASSORTMENT_ALTERNATE = "is an alternate assortment. ";
export const CURRENT = "current";
export const UPCOMING = "upcoming";
