import React from 'react';
import no_img_avlbl from "../images/image_not_found.jfif";

export const fetchImage = {
  getImage: (props) => {
    const { altUrl } = props;
    return <img {...props} onLoad = {
      function ({target:image}){
        if(image && (image.offsetWidth<=1)){
          image.src = altUrl?altUrl:no_img_avlbl;
        }
      }}
      onError={(e) => { e.target.src = no_img_avlbl; }} alt='img'/>
  }
};

export default fetchImage;