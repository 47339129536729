import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { logout } from "../../views/Login/actions";
// import Header from '../../components/NavBar/Header';
import { connect } from "react-redux";
import Footer from "../../components/Footer";
import AllOrders from "../../views/AllOrders";
import history from "../../history";
import ScrollToTop from "../../components/ScrollToTop";
import AuthPage from "./AuthPage";
import { Loader } from "semantic-ui-react";
import HeaderTop from "../../components/HeaderTop";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    this.clearSessionTimeout = this.clearSessionTimeout.bind(this);
    this.setSessionTimeout = this.setSessionTimeout.bind(this);

    for (let i in this.events) {
      window.addEventListener(this.events[i], this.clearSessionTimeout);
    }
    this.setSessionTimeout();
  }

  clearSessionTimeout() {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }

    this.setSessionTimeout();
  }

  setSessionTimeout() {
    this.logoutTimeout = setTimeout(() => {
      this.props.history.push("/");
      this.props.logout();
    }, 30 * 60 * 1000);
  }

  render() {
    return (
      <>
        <HeaderTop
          content={this.props.children}
          // loggedInUser={this.props.loggedInUser}
          storeId={this.props.storeId}
          userName={this.props.userName}
          storeName={this.props.storeName}
        />

        <Footer />
      </>
    );
  }
}

const mapStateToLayoutProps = (state) => {
  return {
    ...state.SessionReducer,
  };
};

const LayoutContainer = connect(mapStateToLayoutProps, { logout })(Layout);

class RenderRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      // If we need Layout and AuthErrorBoundary for a screen, need_layout_and_auth flag should be yes
      // If we have to pass any params to a screen need_props should be yes.
      list_of_pages: [
        // {
        //   path: '/error',
        //   component: ErrorPage,
        //   need_props: 'no'
        // },
        {
          path: "/",
          component: AuthPage,
          need_props: "no",
        },
        {
          path: "/home",
          component: AllOrders,
          need_props: "no",
        },
      ],
    };
  }
  componentDidMount=()=>{
    console.log("calling from router.js")
  }
  componentWillReceiveProps(newProps) {
    if (newProps.showLoader !== this.state.showProps) {
      this.setState({ showLoader: newProps.showLoader });
    }
  }
  componentWillUnmount =()=>{
    localStorage.clear();
    sessionStorage.clear();
  }
  getRenderRoute(component, props) {
    const { showLoader } = this.state;
    return (
      <AuthPage>
        <LayoutContainer
          isAuthenticated={this.props.isAuthenticated}
          {...props}
        >
          {component}
          {showLoader && <Loader active className="commonLoader" />}
        </LayoutContainer>
      </AuthPage>
    );
  }

  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            {this.state.list_of_pages.map((item, index) => (
              <Route
                key={index}
                exact
                path={item.path}
                render={(props) =>
                  this.getRenderRoute(<item.component {...props} />, props)
                }
              />
            ))}
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.SessionReducer.isAuthenticated,
  showLoader: state.UtilityReducer.showLoader,
  storeId: state.SessionReducer.storeId,
  storeName: state.SessionReducer.storeName,
  userName: state.SessionReducer.userName,
  userId: state.SessionReducer.UserId,
  // loggedInUser: state.SessionReducer.address.userName||'',
});

export default connect(mapStateToProps)(RenderRoutes);
