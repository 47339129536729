import {
  LOGIN,
  LOGOUT,
  AUTHORIZATION,
  SET_AUTHENTICATION,
  GET_SECRET,
  SET_CHILDSTORESELECTED,
  HIDEREUNIONPOPUP,
  SETMENU,
  GET_MRI_DOCUMENTS,
  MRI_ERROR_MESSAGE
} from "./actions";

const initState = { isAuthenticated: false };
const SessionReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        userName: payload.username,
        UserId: payload.UserId,
        storeId: payload.storeId,
        isAuthenticated: payload.isAuthenticated,
        address: payload.address,
        rdc: payload.address.rdc,
        mainRDCUnderEmergency: payload.mainRDCUnderEmergency,
        viewOnly: payload.viewOnly,
        multiStoreDetails: payload.multiStoreDetails,
        loadCipherLab: payload.loadCipherLab,
      };
    case AUTHORIZATION:
      const { id_token, access_token, expiresAt } = payload;
      return {
        ...state,
        id_token,
        access_token,
        expiresAt,
        isAuthenticated: true,
      };
    case SET_AUTHENTICATION:
      return { ...state, isAuthenticated: payload.isAuthenticated };
    case LOGOUT:
      return {
        isAuthenticated: false,
      };
    case GET_SECRET:
      return {
        ...state,
        secretKey: payload,
      };
    case SET_CHILDSTORESELECTED:
      return {
        ...state,
        childStoresSelected: payload.childStoresSelected,
      };
    case HIDEREUNIONPOPUP:
      return {
        ...state,
        reunionPop: payload,
      };
    case SETMENU:
      return {
        ...state,
        entireMenu: payload,
      };
    case GET_MRI_DOCUMENTS:
      return {
        ...state,
        getMRIDocumentList: payload,
      };
    case MRI_ERROR_MESSAGE:
      return {
        ...state,
        getErrorMessage: payload,
      };
    default:
      return state;
  }
};

export default SessionReducer;
