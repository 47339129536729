import {SET_LOADER} from './actions';

const initState = {};

const UtilityReducer = (state=initState,{type, payload}) =>{
    switch(type){
        case SET_LOADER: return {...state, showLoader:payload};
        default : return state;
    }
}

export default UtilityReducer;