import React, { Component } from "react";
import { Dropdown, DropdownItem } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import "./NavBar.css";
import { connect } from "react-redux";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { logout } from "../views/Login/actions";
import tv_logo from "../views/images/Logo.svg.png";
import renderImages from "../views/common/RenderImages";
import {
  account,
  getRedirectUriMOL,
  getRedirectUriNNWH,
} from "../urlConstants";
import utilities from "../utils/utilities";
import { Event } from "../history";

class HeaderTop extends Component {
  constructor(props) {
    super(props);
    this.state = { showOptionsDiv: false, showProfileMenu: false };
  }
  redirectHome = () => {
    // this.props.history.push('/home');
  };
  redirectMol = async () => {
    await localStorage.setItem("isLeaveNW", true);
    localStorage.clear();
    sessionStorage.clear();
    window.location.assign(getRedirectUriMOL());
  };
  redirectNNWH = async () => {
    await localStorage.setItem("isLeaveNW", true);
    localStorage.clear();
    sessionStorage.clear();

    window.location.assign(getRedirectUriNNWH());
  };
  // Making the profile menu dropdown controlled one
  toggleProfileMenu = () => {
    const { showProfileMenu } = this.state;
    this.setState({
      showProfileMenu: !showProfileMenu,
    });
  };
  accountClick = () => {
    Event("HEADER", "Account Clicked");
    utilities.openInNewTab(account);
  };
  MouseEnterProfileMenu = () => {
    this.setState({
      showProfileMenu: true,
      showCartMenu: false,
    });
  };

  MouseLeaveProfileMenu = () => {
    this.setState({
      showProfileMenu: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="header-top ui stackable grid zero-margin"
          computer={3}
          tablet={2}
        >
          <div className="header-top-left">
            <div className="header_Logo ">
              <div className="header_logo_img">
                {renderImages.getImage({
                  src: tv_logo,
                  align: "left",
                  alt: "truevalue",
                  className: "cursor_p",
                  onClick: this.redirectNNWH,
                })}
              </div>
              <div className="Netwarehouse">
                &nbsp;&nbsp;
                <span className="Netwarehouse_text">MRI Documents</span>
              </div>
            </div>
            <div
              className="mol"
              style={{ marginLeft: "0.5rem" }}
              onClick={() => this.redirectMol()}
            >
              <i className="home icon clr_blk cursor_p"></i>
              &nbsp;<span className="mol_text">Membersonline</span>
            </div>
          </div>
          <div className="header-top-filler">
            <div
              className="space_ref"
              onMouseEnter={this.MouseLeaveProfileMenu}
            ></div>
            <div
              className="space_ref_top"
              onMouseEnter={this.MouseLeaveProfileMenu}
            ></div>
          </div>
          <div className="header-top-filler">
            <div
              className="space_ref"
              onMouseEnter={this.MouseLeaveProfileMenu}
            ></div>
            <div
              className="space_ref_top"
              onMouseEnter={this.MouseLeaveProfileMenu}
            ></div>
          </div>
          <div className="header_icons header-top-right">
            <div className="header_user_icons_text">
              <span className="name_card">Hello, {this.props.userName}</span>
              <span className="Netwarehouse store_no clr_blk">
                {window.innerWidth >= 768 && <span>&nbsp;</span>}
                Store #{this.props.storeId}
                <span className="header-store-name">
                  ,&nbsp;{this.props.storeName}
                </span>
              </span>
            </div>
            <div className="header_user_icons">
              <Dropdown
                className="pointing top right"
                icon={
                  <i
                    title="User Profile"
                    className="square large user icon cursor_p"
                  />
                }
                onClick={this.toggleProfileMenu}
                onMouseEnter={this.MouseEnterProfileMenu}
                open={this.state.showProfileMenu}
              >
                <Dropdown.Menu
                  onMouseLeave={this.MouseLeaveProfileMenu}
                  className="user-menu"
                  id="user-menu"
                >
                  <div>
                    <DropdownItem className="user-menu-option">
                      Hello, {this.props.userName || ""}
                    </DropdownItem>
                    {/* <Divider className="user-menu-divider" /> */}

                    {/* <DropdownItem
                    key="account"
                    onClick={this.accountClick}
                    className="user-menu-option"
                  >
                    Account
                  </DropdownItem> */}

                    {/* <DropdownItem
                      key="redirectToMOL"
                      onClick={() => this.redirectNNWH()}
                      className="user-menu-option"
                    >
                      Return To NW
                    </DropdownItem> */}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div>{this.props.content}</div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const userName = state.SessionReducer.userName;
  const LoggedUser =
    state.SessionReducer.address && state.SessionReducer.address.userName;
  const storeId = state.SessionReducer.storeId;
  const storeName =
    state.SessionReducer.address && state.SessionReducer.address.storeName;
  return {
    userName,
    LoggedUser,
    storeId,
    storeName,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return Object.assign({ dispatch }, bindActionCreators({ logout }, dispatch));
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderTop)
);
