import React from "react";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import ReduxToastr from "react-redux-toastr";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import RenderRoutes from "./views/common/RenderRoutes";
import "../src/views/common/snackbar.css";

const App = () => (
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
  >
    {" "}
    <ReduxToastr
      timeOut={4000}
      newestOnTop
      preventDuplicates
      position="top-center"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
    />
    <div>
      {" "}
      <RenderRoutes />
      <div id="snackbar"></div>
    </div>
  </CacheBuster>
);

export default App;
