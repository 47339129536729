import React from "react";
import FileSaver from "file-saver";
import { Loader, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
// import {sha256_digest} from "js-sha256"
import "react-datepicker/dist/react-datepicker.css";
import restUtils from "../../utils/restUtils";
import "./AllOrders.css";
import utilities from "../../utils/utilities";
import { downloadMRIDocumentUri } from "../../../src/urlConstants";
import { getMRIDocuments } from "../Login/actions";
import DownloadReport from "../images/print.png";
import search_img from "../images/Search.svg";
import close_btn from "../images/close_btn.png";
import refresh_icon from "../images/refresh_icon.png";
import renderImages from "../../views/common/RenderImages";
//import sha256 from 'js-sha256';
const jssha = require("js-sha256");

class AllOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: false,
      noOfOrders: 0,
      scrollPostion: 0,
      firstItem: 0,
      lastItem: 0,
      currentPage: 1,
      totalPages: 0,
      filterForRequest: {},
      showItemPerPage: 50,
      searchInput: "",
      sortParam: "orderDate",
      sortDirection: "desc",
      searchForDate: "",
      searchFieldError: false,
      searchLoading: false,
      filterType: "none",
    };

    this.scrollPosition = 0;
  }

  onPageChange = (e) => {
    let queryPage = e.currentTarget.text;

    if (e.currentTarget.text === "Next") {
      queryPage =
        this.state.currentPage < this.state.totalPages
          ? parseInt(this.state.currentPage) + 1
          : this.state.currentPage;
    }
    if (e.currentTarget.text === "Prev") {
      queryPage =
        this.state.currentPage === 1 ? 1 : parseInt(this.state.currentPage) - 1;
    }
    if (e.currentTarget.text === "»") {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === "«") {
      queryPage = 1;
    }
    debugger;
    this.setState({ currentPage: queryPage }, () => this.getOrders());

    this.page = queryPage;
    this.scrollPosition = window.pageYOffset;
    window.scrollTo(0, 0);
  };
  onDateChange = (date) => {
    this.setState({ searchForDate: date, filterType: "CreatedDate" });
  };
  convertDate = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  componentDidCatch = () => {
    this.getOrders();
  };

  componentDidMount = async () => {
    this.getOrders();
  };

  componentWillUnmount = () => {
    localStorage.clear();
    sessionStorage.clear();
  };
  componentDidUpdate(preProps, preState) {
    const { getMRIDocumentList } = this.props;

    try {
      if (preProps.getMRIDocumentList !== this.props.getMRIDocumentList) {
        this.setPagination(getMRIDocumentList);
      }

      if (preState.showItemPerPage !== this.state.showItemPerPage) {
        this.setPagination(getMRIDocumentList);
      }
    } catch (error) {
      this.setState({ loading: false });
      utilities.showToast(error, true);
    }
  }
  setPagination = (data) => {
    const { showItemPerPage } = this.state;
    const { mRIMetaDataList, pageCount, totalCount } = data;

    this.setState({
      allRecipts: mRIMetaDataList,
      loading: false,
      noOfOrders: totalCount,
      serverError: false,
      totalPages: pageCount,
    });
  };

  getOrders = async () => {
    const {
      showItemPerPage,
      currentPage,
      sortDirection,
      filterType,
      filterValues,
    } = this.state;
    this.setState({ loading: true });

    const member = this.props.storeId;

    const config = {
      rowsPerPage: showItemPerPage,
      pageNbr: currentPage,
      sortColumn: 1,
      sortOrder: sortDirection,
      filterType: filterType,
      filterValues: filterValues,
    };
    await this.props.getMRIDocuments(member, config);
    this.setState({ loading: false });
  };

  hash = (input) => {
    const hashString = "0x" + jssha.sha256(input);
    return hashString;
  };

  download_MRI_PDF = async (id) => {
    this.setState({ loading: true });

    const member = this.props.storeId;
    const url = `${downloadMRIDocumentUri()}/${id}/Member/${member}`;
    await restUtils
      .getDataMRI(url)
      .then(async (response) => {
        const rec_url = response.data;

        const urlWithoutHash = rec_url.replace(/&hash=.*/, "");
        const receivedHash = rec_url.match(/&hash=(.*)/)[1];

        const calculatedHash = this.hash(urlWithoutHash);

        if (receivedHash === calculatedHash) {
          await FileSaver.saveAs(urlWithoutHash, id);
        } else {
          utilities.showToast(
            "You are not authroized to downolad this PDF ",
            true
          );
        }

        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        utilities.showToast(error, true);
      });
  };

  searchItems = () => {
    const { searchForDate } = this.state;
    if (!searchForDate) {
      return this.setState({ searchFieldError: true });
    }
    const dateRange = this.convertDate(searchForDate);
    this.setState(
      {
        loading: true,
        filterValues: [dateRange],
        currentPage: 1,
        searchLoading: true,
      },
      () => this.getOrders()
    );
  };
  changeSortParam = (e) => {
    const sortBy = e.currentTarget.value;
    this.setState({ sortParam: sortBy });
  };

  changeSortDirection = (e) => {
    const sortDirection = e.currentTarget.value;
    this.setState({ sortDirection: sortDirection, loading: true }, () =>
      this.getOrders()
    );
  };

  onClickCloseBtn = () => {
    this.setState(
      {
        searchForDate: "",
        searchLoading: false,
        filterType: "None",
        filterValues: [],
        currentPage: 1,
        loading: true,
      },
      () => this.getOrders()
    );
  };
  // reloadPage = () => {
  //   this.getOrders();
  // };
  onOrderPageSize = (e) => {
    const pageSize = e.target.value;
    this.setState(
      { showItemPerPage: pageSize, currentPage: 1, loading: true },
      () => this.getOrders()
    );
  };

  render() {
    const {
      currentPage,
      showItemPerPage,
      noOfOrders,
      searchFieldError,
      allRecipts,
      searchLoading,
      loading,
    } = this.state;

    const indexOfLastPage = currentPage * showItemPerPage;
    const indexOfFirstPage = indexOfLastPage - showItemPerPage;
    const firstItem = indexOfFirstPage + 1;
    const lastItem =
      noOfOrders > indexOfLastPage ? indexOfLastPage : noOfOrders;
    return (
      <div className="page-wrap">
        {/* {this.props.getErrorMessage ? (
          <h4>
            You don't have the permission to view this page.Please contact the
            True Value Administrator.
          </h4>
        ) : ( */}
        <>
          <div className="page-header editOrderHeaderTop">
            <div className="editOrderHeaderTwo">
              <div className="sortFields">
                <div id="input_search">
                  <span>Search</span>
                  <DatePicker
                    showIcon
                    id="search_order_status"
                    placeholderText="Select date"
                    selected={this.state.searchForDate}
                    onChange={(date) => this.onDateChange(date)}
                    maxDate={new Date()}
                    minDate={new Date().setDate(new Date().getDate() - 180)}
                    onCalendarOpen={() =>
                      this.setState({ searchFieldError: false })
                    }
                  />

                  {!searchLoading
                    ? renderImages.getImage({
                        src: search_img,
                        className: "close_icon cursor_p",
                        onClick: this.searchItems,
                      })
                    : renderImages.getImage({
                        src: close_btn,
                        className: "close_icon cursor_p",
                        onClick: this.onClickCloseBtn,
                      })}
                  {/* {renderImages.getImage({
                  src: refresh_icon,
                  className: "refresh_icon_address cursor_p",
                  onClick: this.reloadPage,
                })} */}
                </div>
                <div className="sort_by mobile_sort">
                  <span className="filter-label">Show</span>
                  <select
                    value={showItemPerPage}
                    className="select-range combo"
                    style={{ marginRight: "0.5rem" }}
                    onChange={this.onOrderPageSize}
                  >
                    <option className="SelectOption" value={50}>
                      50
                    </option>
                    <option className="SelectOption" value={100}>
                      100
                    </option>
                  </select>

                  <span className="filter-label">Sort By</span>
                  <select
                    className="sort-criteria combo"
                    onChange={this.changeSortParam}
                    id="pl_dropdown"
                    style={{ width: "12.7rem" }}
                    value={this.state.sortParam}
                  >
                    <option value="orderDate">MRI Document Date</option>
                    {/* <option value="invoiceNbr">Invoice #</option> */}
                  </select>
                  <select
                    className="sort-order combo"
                    onChange={this.changeSortDirection}
                    defaultValue="desc"
                    value={this.state.sortDirection}
                  >
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </div>
              </div>
              <div>
                {searchFieldError && (
                  <div className="warning-txt">Please select a date*</div>
                )}
              </div>
            </div>
            {noOfOrders > 0 && (
              <div className="order-pagination">
                <span className="OrderTotalitem">
                  {firstItem}-{lastItem} of {noOfOrders} Documents
                </span>
                <span className="paginationAllorders">
                  <Pagination
                    className="pagination"
                    boundaryRange={0}
                    activePage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.onPageChange}
                    ellipsisItem={null}
                    siblingRange={1}
                    firstItem={
                      this.state.currentPage == 1
                        ? {
                            content: <span className="disabled">«</span>,
                          }
                        : { content: "«" }
                    }
                    lastItem={
                      this.state.currentPage == this.state.totalPages
                        ? {
                            content: <span className="disabled">»</span>,
                          }
                        : { content: "»" }
                    }
                    prevItem={
                      this.state.currentPage == 1
                        ? {
                            content: <span className="disabled">Prev</span>,
                          }
                        : { content: "Prev" }
                    }
                    nextItem={
                      this.state.currentPage == this.state.totalPages
                        ? {
                            content: <span className="disabled">Next</span>,
                          }
                        : { content: "Next" }
                    }
                  />
                </span>
                <span className="order_count">
                  Total {noOfOrders} Documents
                </span>
              </div>
            )}
          </div>

          {loading && (
            <div>
              <Loader active />
            </div>
          )}

          <div className="allOrdersTable">
            <div className="eighteen wide column" id="allOrdersTable">
              <table
                className="ui celled table all-wh-table stickyTableEditOrder"
                id="font-size-orders"
              >
                <thead>
                  <tr>
                    <th className="allOdrHeader" id="no_border">
                      MRI Document Date
                    </th>
                    <th className="allOdrHeader" id="no_border">
                      MRI Document Number
                    </th>
                    <th className="allOdrHeader" id="no_border">
                      Download MRI PDF
                    </th>
                  </tr>
                </thead>
                <tbody style={{ overflowY: true }}>
                  {!this.state.noOfOrders || this.state.serverError ? (
                    <>
                      <tr>
                        <td colSpan="11" className="t-center">
                          No Document to display!
                        </td>
                      </tr>
                    </>
                  ) : (
                    allRecipts.map((order, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className="t-center" id="remove_border">
                              {utilities
                                .hyphenForNull(order.createdDate)
                                .slice(0, 10) || "-"}
                            </td>
                            <td className="t-center" id="remove_border">
                              {utilities.hyphenForNull(order.invoiceNbr) || "-"}
                            </td>

                            <td
                              colSpan="1"
                              className="checkoutPointer"
                              id="remove_border"
                            >
                              <img
                                style={{ width: "20px" }}
                                src={DownloadReport}
                                id="downloadButton"
                                alt="download"
                                onClick={() => this.download_MRI_PDF(order.id)}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
              {noOfOrders > 0 && (
                <div className="order-pagination">
                  <span className="OrderTotalitem">
                    {firstItem}-{lastItem} of {noOfOrders} Documents
                  </span>
                  <span className="paginationAllorders">
                    <Pagination
                      className="pagination"
                      boundaryRange={0}
                      activePage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.onPageChange}
                      ellipsisItem={null}
                      siblingRange={1}
                      firstItem={
                        this.state.currentPage == 1
                          ? { content: <span className="disabled">«</span> }
                          : { content: "«" }
                      }
                      lastItem={
                        this.state.currentPage === this.state.totalPages
                          ? { content: <span className="disabled">»</span> }
                          : { content: "»" }
                      }
                      prevItem={
                        this.state.currentPage == 1
                          ? {
                              content: <span className="disabled">Prev</span>,
                            }
                          : { content: "Prev" }
                      }
                      nextItem={
                        this.state.currentPage == this.state.totalPages
                          ? {
                              content: <span className="disabled">Next</span>,
                            }
                          : { content: "Next" }
                      }
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
        {/* )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const storeId = state.SessionReducer.storeId;
  const userName = state.SessionReducer.userName;
  const userId = state.SessionReducer.UserId;
  //const loggedInUser = state.SessionReducer.address.userName;
  const rdcNum = state.SessionReducer.rdc;
  const storeName =
    state.SessionReducer.address && state.SessionReducer.address.storeName;

  return {
    getMRIDocumentList: state.SessionReducer.getMRIDocumentList || [],
    getErrorMessage: state.SessionReducer.getErrorMessage || "",
    storeId: storeId,
    storeName,
    userName: userName,
    // loggedInUser: loggedInUser,
    userId: userId,
    rdcNum,
    getprefer: "",
    viewOnly: state.SessionReducer.viewOnly,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    aliasSKURetailerGrpID: state.SessionReducer.address
      ? state.SessionReducer.address.aliasSKURetailerGrpID
      : "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ getMRIDocuments }, dispatch)
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllOrders)
);
